// Requires that wherever you are importing, you have the config & viewport reducers imported in your configure_store or combinedReducer entrypoints
export const isMobile = (state) => {
	// eslint-disable-next-line no-console
	console.warn(
		'isMobile is deprecated. Please use state property state.viewport.isMobile',
	);
	return !!(state.viewport.lessThan.medium || state.config.device === 'mobile');
};

export const determineReferredMarketCode = (opts) => {
	const { cookieMarketCode, isFromSearch, isReferred, marketCode } = opts;

	const isValid = isReferred || isFromSearch;
	const referredMarketCode = cookieMarketCode || marketCode;

	return isValid ? referredMarketCode : null;
};

export const buildSingleLeadSuccessCopy = (vendorName) => {
	return `Great job! You sent a quote request to ${vendorName}. Check your inbox for a reply.`;
};

export const buildMultipleLeadSuccessCopy = (leadCount) => {
	return `Great job! You sent quote requests to ${leadCount} vendors. Check your inbox for a reply.`;
};

// This is the slugify logic from search API in the isPermitted resolver
// If our parsed city doesn't slugify to a valid slug, the given location slug is invalid
// https://github.com/tkww/marketplace-search-api/blob/9f660878c0526696dd49e762acb7e039b97190d7/src/helpers/slugify.js
export const validateCitySlug = (city = '') =>
	city
		.replace(/[~!@#$%^*(){}[\];:"<,.>?/\\|+=]/g, '')
		.replace(/\s+/g, '-')
		.toLowerCase();
