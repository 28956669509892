// source https://github.com/hapijs/hoek  lib/index.js
class Hoek {
	static transform(source, transform, options) {
		Hoek.assert(
			source === null ||
				source === undefined ||
				typeof source === 'object' ||
				Array.isArray(source),
			'Invalid source object: must be null, undefined, an object, or an array',
		);
		const separator =
			typeof options === 'object' && options !== null
				? options.separator || '.'
				: '.';

		if (Array.isArray(source)) {
			const results = [];
			for (let i = 0; i < source.length; ++i) {
				results.push(Hoek.transform(source[i], transform, options));
			}
			return results;
		}

		const result = {};
		const keys = Object.keys(transform);

		for (let i = 0; i < keys.length; ++i) {
			const key = keys[i];
			const path = key.split(separator);
			const sourcePath = transform[key];

			Hoek.assert(
				typeof sourcePath === 'string',
				'All mappings must be "." delineated strings',
			);

			let segment;
			let res = result;

			while (path.length > 1) {
				segment = path.shift();
				if (!res[segment]) {
					res[segment] = {};
				}
				res = res[segment];
			}
			segment = path.shift();
			res[segment] = Hoek.reach(source, sourcePath, options);
		}

		return result;
	}

	static reach(obj, chain, options) {
		if (chain === false || chain === null || typeof chain === 'undefined') {
			return obj;
		}

		options = options || {};
		if (typeof options === 'string') {
			options = { separator: options };
		}

		const path = chain.split(options.separator || '.');
		let ref = obj;
		for (let i = 0; i < path.length; ++i) {
			let key = path[i];
			if (key[0] === '-' && Array.isArray(ref)) {
				key = key.slice(1, key.length);
				key = ref.length - key;
			}

			if (
				!ref ||
				!(
					(typeof ref === 'object' || typeof ref === 'function') &&
					key in ref
				) ||
				(typeof ref !== 'object' && options.functions === false)
			) {
				// Only object and function can have properties

				Hoek.assert(
					!options.strict || i + 1 === path.length,
					'Missing segment',
					key,
					'in reach path ',
					chain,
				);
				Hoek.assert(
					typeof ref === 'object' ||
						options.functions === true ||
						typeof ref !== 'function',
					'Invalid segment',
					key,
					'in reach path ',
					chain,
				);
				ref = options.default;
				break;
			}

			ref = ref[key];
		}

		return ref;
	}

	static assert(condition) {
		if (condition) {
			return;
		}

		if (arguments.length === 2 && arguments[1] instanceof Error) {
			throw arguments[1];
		}

		let msgs = [];
		for (let i = 1; i < arguments.length; ++i) {
			if (arguments[i] !== '') {
				msgs.push(arguments[i]); // Avoids Array.slice arguments leak, allowing for V8 optimizations
			}
		}

		msgs = msgs.map((msg) => {
			return typeof msg === 'string'
				? msg
				: msg instanceof Error
				  ? msg.message
				  : JSON.stringify(msg);
		});

		throw new Error(msgs.join(' ') || 'Unknown error');
	}

	static clone(obj, seen) {
		if (typeof obj !== 'object' || obj === null) {
			return obj;
		}

		seen = seen || new Map();

		const lookup = seen.get(obj);
		if (lookup) {
			return lookup;
		}

		let newObj;
		let cloneDeep = false;

		if (!Array.isArray(obj)) {
			if (Buffer.isBuffer(obj)) {
				newObj = new Buffer(obj);
			} else if (obj instanceof Date) {
				newObj = new Date(obj.getTime());
			} else if (obj instanceof RegExp) {
				newObj = new RegExp(obj);
			} else {
				const proto = Object.getPrototypeOf(obj);
				if (proto && proto.isImmutable) {
					newObj = obj;
				} else {
					newObj = Object.create(proto);
					cloneDeep = true;
				}
			}
		} else {
			newObj = [];
			cloneDeep = true;
		}

		seen.set(obj, newObj);

		if (cloneDeep) {
			const keys = Object.getOwnPropertyNames(obj);
			for (let i = 0; i < keys.length; ++i) {
				const key = keys[i];
				const descriptor = Object.getOwnPropertyDescriptor(obj, key);
				if (descriptor && (descriptor.get || descriptor.set)) {
					Object.defineProperty(newObj, key, descriptor);
				} else {
					newObj[key] = Hoek.clone(obj[key], seen);
				}
			}
		}

		return newObj;
	}

	static merge(
		target,
		source,
		isNullOverride /* = true */,
		isMergeArrays /* = true */,
	) {
		/* eslint-enable */

		Hoek.assert(
			target && typeof target === 'object',
			'Invalid target value: must be an object',
		);
		Hoek.assert(
			source === null || source === undefined || typeof source === 'object',
			'Invalid source value: must be null, undefined, or an object',
		);

		if (!source) {
			return target;
		}

		if (Array.isArray(source)) {
			Hoek.assert(Array.isArray(target), 'Cannot merge array onto an object');
			if (isMergeArrays === false) {
				// isMergeArrays defaults to true
				target.length = 0; // Must not change target assignment
			}

			for (let i = 0; i < source.length; ++i) {
				target.push(Hoek.clone(source[i]));
			}

			return target;
		}

		const keys = Object.keys(source);
		for (let i = 0; i < keys.length; ++i) {
			const key = keys[i];
			const value = source[key];
			if (value && typeof value === 'object') {
				if (
					!target[key] ||
					typeof target[key] !== 'object' ||
					Array.isArray(target[key]) !== Array.isArray(value) ||
					value instanceof Date ||
					Buffer.isBuffer(value) ||
					value instanceof RegExp
				) {
					target[key] = Hoek.clone(value);
				} else {
					Hoek.merge(target[key], value, isNullOverride, isMergeArrays);
				}
			} else if (value !== null && value !== undefined) {
				// Explicit to preserve empty strings

				target[key] = value;
			} else if (isNullOverride !== false) {
				// Defaults to true
				target[key] = value;
			}
		}

		return target;
	}

	static applyToDefaults(defaults, options, isNullOverride) {
		Hoek.assert(
			defaults && typeof defaults === 'object',
			'Invalid defaults value: must be an object',
		);
		Hoek.assert(
			!options || options === true || typeof options === 'object',
			'Invalid options value: must be true, falsy or an object',
		);

		if (!options) {
			// If no options, return null
			return null;
		}

		const copy = Hoek.clone(defaults);

		if (options === true) {
			// If options is set to true, use defaults
			return copy;
		}

		return Hoek.merge(copy, options, isNullOverride === true, false);
	}
}

export default Hoek;
