import { getPlatform } from '@utils/getPlatform';
import { CookieStorage } from 'cookie-storage';
import { EventProperties } from 'types/analytics';

const cookieStorage = new CookieStorage();

export const constructAnalyticsProperties = (properties: EventProperties) => ({
	platform: getPlatform(),
	product: 'marketplace',
	visitId: cookieStorage.getItem('visit_id'),
	visitorId: cookieStorage.getItem('visitor_id'),
	...properties,
});
