// @ts-check

import { withinDateRange } from '../client/utils/withinDateRange';

/* host names */
export const tkCanonicalHost = 'https://www.theknot.com';
export const tkHost = 'https://www.theknot.com';
export const tkNext =
	'https://tk-marketplace-fe-nextjs-prod.k8s.localsolutions.theknot.com';

/* api keys */
export const geoApiKey = 'vkq9ckuqn9c7jprn92uwbsjkzmtbk6pdxh9';
export const weddingsApiKey = 'F2HB4UM97Www1YgSB2Nd85ueRqJIBprU';
export const membershipApiKey = 'xO42dFh78Uy5z351IDJwVuwYV86yZkWV';
export const honeybadgerApiKey = '5d16705f';
export const honeybadgerClientApiKey =
	'hbp_UEbghvICIQDYZbnCrwz7GfUYPHBfer0irsVE';
export const honeybadgerServerApiKey =
	'hbp_ydqlDvyLrRqlwkdrbHpt3vZOESUG1V2HmxAs';
export const analyticsWriteKey = '0KXGZo0hporpqj3UE8vz6GNa1U0GIs45';
export const reviewsApiKey = 'abusHgT1FntDGOHS9sJo9WpoNmldKBhPPqWMcpck';
export const mediaApiKey = '10750abefb7f4f0606a162225d8b93a5';

/* endpoints */
export const mediaApiUrl = 'https://media-api.xogrp.com/images/';
export const noServicesUrl = 'https://no-services.theknot.com';
export const weddingsRootUrl = 'https://api.weddings.xogrp.com';
/* from George M. going forward there is no internal K8s url */
export const graphqlApiUrlK8 =
	'https://prod-marketplace-api.localsolutions.theknot.com/graphql';

export const graphqlApiUrl =
	'https://prod-marketplace-api.localsolutions.theknot.com/graphql';
export const reviewsApiUrl = `${noServicesUrl}/local-partners/api/reviews`;
export const reviewAiSummaryUrl =
	'https://review-summaries.dataops.nuptic.com/predict';
export const taxonomyApi = 'https://api.dataintel.xogrp.com/taxonomy/knot';
export const experimentAPI =
	'https://api.experimentation.xogrp.com/experiments';
export const identityApi =
	'https://prod-identity-api.localsolutions.theknot.com/identities';
export const internalCreateImageReviewsUrl =
	'/marketplace/reviews/images/create';
export const internalDeleteImageReviewsUrl =
	'/marketplace/reviews/images/delete';

export const gtmId = 'GTM-KBVGRZX';
export const gaTrackingId = 'UA-776588-1';

export const tkProUrl = 'https://partnerssignup.theknot.com';
export const vendorManagerUrl = 'https://theknot.com/wedding-vendor-manager';

export const conversationsApi = `${noServicesUrl}/local-partners/api/conversation-theknot/conversations`;
export const conversationsAuthApi = `${noServicesUrl}/local-partners/api/app-auth/auth/member`;
export const transactionsAPIUrl = `${noServicesUrl}/local-partners/api/transactions`;
export const bulkInquireAPIUrl = `${noServicesUrl}/local-partners/api/conversation-theknot/conversations/bulkInquire`;
export const inquireAPIUrl = `${noServicesUrl}/local-partners/api/conversation-theknot/conversations/inquire`;
export const inboxUrl = `${tkHost}/inbox/conversations`;
export const semanticSearchApiUrl =
	'https://style-quiz-ai-recos.dataops.nuptic.com/predict';

/* api keys */
export const pvRecApiKey = 'de4c24647278487eb19195721cb35280';

/* gtm experiments */
export const hideFeaturedExperimentId = 'sh9nlHrLSyWTpZbNifXLSA';

/* experiment API keys */
export const experimentAPIKey = '26b222c7690da5916c5c62d92814f668';

const unknownId = 'UNKNOWN_PROD_ID';
/** @type Experiments.Settings */
export const experiments = {
	vrmMatchDetails: 'b1b20e31-76b6-4772-9cc7-c905f85b571d',
	reorderAllFilters: 'a68b6209-0fbd-4630-bfcf-fbfff15ee539',
	reviewAiSummary: '40f3c82f-e42b-4665-b446-64539a42d66f',
	tkNoContentBlurbs: '8b6f5a34-404e-490c-bd2c-855be6f6faa9',
	refreshResults: 'efac327c-fadc-4d25-9609-aba8193ff115',
	leadFormSequencing_v1_1: 'f2ed5e28-bf07-450c-9606-29d9135dc573',
	branchBanner: 'a800566e-82de-41eb-a4f0-f8a01e5346be',
	vendorRecommendationDistance: 'dc58a275-f827-45d3-818c-1aebf2b3885f',
	betterImage: 'c5c5131b-c599-4c56-ba73-078a2d10c2ad',
	newHeart: '6235b010-edea-4a57-968d-87a349664fa9',
	mobileMapView: '248e6b05-892e-4090-8020-95122ce3534f',
	quickResponder: 'f2996416-487c-4ff0-a9da-613855793d5e',
	semanticSearch: '4cc0dcda-0c2a-44ec-9063-ef68721c8e31',
	'pvrec2.0': '2233c69c-0ad3-4a4c-8d30-614b4c3e91ce',
	storefrontLightboxRevamp: '984a69ce-a894-4ce1-a5c3-a1f686ce3ccf',
	VRMAIPhoto: 'e4e5932c-43a9-40d0-9ac5-e7eb5f0f5598',
	directoryInlineRfq: '57431620-56e4-4487-b725-4af31cae7607',
	liteRFQVendorWidget: '8456160c-e9ad-4d73-9ad1-afea7be0ea20',
	reviewFormLoginFlow: 'a5473d55-afb8-45f1-9105-b170276e1b91',
	liteStorefront: '4f13d3ab-1e2d-4307-a479-f83155cad4d8',
	sortPill: '005988ac-1b5c-400a-a459-75528c6711fb',
	y1IVOLv2: 'b3d5c380-0030-47ca-88dd-ed3ebde1ec1b',
	newStartingPrice: '0217106f-de60-482f-b0d9-fd7330c58955',
	expectedValue: '32feb16c-a247-4344-bba4-59254b5aae97',
};

// If your new experiment is a backend experiment add it to this array
// This array of keys will be used to add the assignment when generating a hash key for search results
export const backendExperimentKeys = [experiments.y1IVOLv2];

export const awards = {
	bow: {
		beforeStartDate: {
			year: '2023',
			name: '2023 Best of Weddings',
			filterId: '9b6a49d5-4c91-47c5-a344-1bb9cfde70cb',
			filterSubText: 'Top Rated Vendors of the Year',
			slug: 'current-winner',
			iconId: 'd9bdff24-9960-459f-9a44-41a627b76da2',
		},
		afterStartDate: {
			year: '2024',
			name: '2024 Best of Weddings',
			filterId: '0b541473-2e05-47e6-8dc2-b635477785fc',
			filterSubText: 'Top Rated Vendors of the Year',
			slug: 'current-winner',
			iconId: 'd15e4891-5b15-4e3e-826e-623b5df9b552',
		},
	},
	hof: {
		beforeStartDate: {
			year: '2023',
			name: 'Hall of Fame',
			filterId: '19f33d4a-0a5a-48e2-af70-39f94ea4ea7f',
			filterSubText: 'Won Best of Weddings 4+ times',
			slug: 'hall-of-fame',
			iconId: 'c8633e9d-8826-47cc-8e1b-2ade19b8df96',
		},
		afterStartDate: {
			year: '2024',
			name: 'Hall of Fame',
			filterId: '19f33d4a-0a5a-48e2-af70-39f94ea4ea7f',
			filterSubText: 'Won Best of Weddings 4+ times',
			slug: 'hall-of-fame',
			iconId: '6738d190-bb59-4af8-bbe3-df752b1f6f51',
		},
	},
	endDate: 'January 17, 2025 00:00:00 EST',
	startDate: 'January 17, 2024 03:00:00 EST',
};

/* base API URL */
export const xoAPIRootUrl = 'https://no-services.theknot.com';
export const marketplaceGraphqlUrl =
	'https://prod-marketplace-api.localsolutions.theknot.com/graphql';
export const xoAPIReviewsStorefrontUrl = `${xoAPIRootUrl}/local-partners/api/reviews/storefront`;
export const xoAPIReviewsMemberUrl = `${xoAPIRootUrl}/local-partners/api/reviews/member`;

export const googleMapsAPIKey = 'AIzaSyD4yMSGEidjsEuFn_M0dQ5Nh25IPa4vla8';

const BOW_CURRENT_WINNER_ID = withinDateRange({
	startDate: awards.startDate,
})
	? awards.bow.afterStartDate.filterId
	: awards.bow.beforeStartDate.filterId;

export const SearchConstants = {
	DEFAULT_LIMIT: 30,
	BOW_CURRENT_WINNER_ID,
	BOW_HALL_OF_FAME_ID: awards.hof.beforeStartDate.filterId,
	BOW_FILTER_SLUG: 'best-of-weddings',
	BOW_MEDAL_MEDIA_ID: 'b87d3db7-35c3-4a97-87db-1059c238c37d',
	INTERNAL_REFERRER_SORT: 'featured',
};

export const adSlotEnv = 'production';

export const gpFeatureFlagEndpointUrl = 'https://www.weddingpro.com';

export const gpBaseUrl = 'https://weddingwire.com';
