import GeoSDK from '@xo-union/sdk-geo';
import MembershipSDK from '@xo-union/sdk-membership';
import WeddingSDK from '@xo-union/sdk-weddings';
import { weddingsApiKey } from '../../../settings';
import Hoek from '../utils/hoek';

const CATEGORY_CODES = {
	REC: 'REC',
};

class WeddingApi {
	constructor({ category, member, formData } = {}) {
		this._member = member && member.id ? member : null;
		this._data = formData && Object.keys(formData).length ? formData : null;
		this._category = category;

		const token = new MembershipSDK().getSessionToken();
		this._client = new WeddingSDK({
			apiKey: weddingsApiKey,
			membershipSessionToken: token,
		});
	}

	_mapGuestCount(input) {
		const output = {
			...input,
			separator: '–',
		};

		if (output.maxGuestCount === 250) {
			output.maxGuestCount = '300';
		}

		if (output.maxGuestCount === 300) {
			output.minGuestCount = '201';
		}

		if (output.maxGuestCount === 0) {
			output.minGuestCount = 300;
			output.separator = '';
			output.maxGuestCount = '+';
		}

		return `${output.minGuestCount}${output.separator}${output.maxGuestCount}`;
	}

	_mapMarket(input) {
		const getMarket = ({ city, state }) =>
			new GeoSDK()
				.locationsByCity('', {
					city,
					state,
				})
				.then((response) => response?.body?.locations[0])
				.catch(() => undefined);

		const mappings = {
			city: 'city',
			state: 'stateCode',
			country: 'countryCode',
			code: 'market',
		};

		if (input.location) {
			return Promise.resolve(this._transformAndClean(input.location, mappings));
		}

		if (input.storefront || input.googlePlace) {
			let cityState;

			if (input.storefront) {
				cityState = {
					city: input.storefront.city,
					state: input.storefront.state,
				};
			} else if (input.googlePlace) {
				cityState = {
					city: input.googlePlace.city,
					state: input.googlePlace.state,
				};
			}

			if (cityState) {
				return getMarket(cityState).then(
					(location) =>
						(location && this._transformAndClean(location, mappings)) ||
						undefined,
				);
			}
		} else {
			// nothing to see here
			return Promise.resolve();
		}
	}

	_buildWedding(wedding) {
		const receptionVenue =
			(this._data &&
				this._data.venue_picker &&
				this._data.venue_picker.receptionVenue) ||
			{};

		return this._mapMarket(receptionVenue).then((market) => {
			const data = {
				_wedding_date:
					this._data &&
					this._data.xo_date_picker &&
					this._data.xo_date_picker.weddingDate,
				_guest_count:
					this._data &&
					this._data.guest_count &&
					this._mapGuestCount(this._data.guest_count.value),
				_market: market,
			};

			// build source
			const source = {
				member: this._member,
				wedding,
				data,
			};

			const mappings = {
				id: 'wedding.id',
				member_id: 'member.id',
				guest_range: 'data._guest_count',
				wedding_date: 'data._wedding_date',
				market: 'data._market',
			};

			const paylod = this._transformAndClean(source, mappings);

			return (
				(paylod &&
					Object.keys(paylod).length && {
						wedding: paylod,
					}) ||
				{}
			);
		});
	}

	getWedding() {
		if (!this._member) {
			return Promise.resolve();
		}

		return this._client
			.getWeddingByMemberId(this._member.id)
			.then((response) => response && response.wedding)
			.catch((error) => {});
	}

	_upsertWedding() {
		// not logged or no data passed, then exit
		if (!(this._member && this._data)) {
			return Promise.resolve();
		}

		return this.getWedding()
			.then((wedding) => this._buildWedding(wedding))
			.then((wedding) => this._client.upsertWedding(wedding));
	}

	_buildBooking = ({ bookings, venue, address }) => {
		const transformAddress = ({ address }) => {
			const storefront = (input) => {
				const mappings = {
					address1: 'local_vendor.address1',
					address2: 'local_vendor.address2',
					city: 'local_vendor.city',
					state: 'local_vendor.state',
					postalCode: 'local_vendor.postalCode',
					latitude: 'local_vendor.latitude',
					longitude: 'local_vendor.longitude',
					isPublic: 'local_vendor.isPublic',
				};

				return {
					local_vendor: this._transformAndClean(input, mappings),
				};
			};

			const googlePlace = (input) => {
				const source = {
					...input,
					locality:
						input.google_place &&
						(input.google_place.locality || input.google_place.sublocality),
				};

				const mappings = {
					place_id: 'google_place.place_id',
					phone_number: 'google_place.phone_number',
					route: 'google_place.route',
					country: 'google_place.country',
					locality: 'locality',
					political: 'google_place.political',
					postal_code: 'google_place.postal_code',
					street_number: 'google_place.street_number',
					administrative_area_level_1:
						'google_place.administrative_area_level_1',
				};

				return {
					google_place: this._transformAndClean(source, mappings),
				};
			};

			return (
				address &&
				((address.local_vendor && storefront(address)) ||
					(address.google_place && googlePlace(address)))
			);
		};

		const transformVenue = ({ member, venue, address }) => {
			const source = {
				member,
				venue,
				address: transformAddress({ address }),
			};
			const mappings = {
				member_id: 'member.id',
				legacy_user_id: 'member.legacy_user_id',
				vendor_profile_id: 'venue.id',
				vendor_name: 'venue.name',
				address_components: 'address',
			};

			return this._transformAndClean(source, mappings);
		};

		const booking = {
			category_code: CATEGORY_CODES.REC,
			source: 'web-local-rfq',
		};

		// match previous booking by vendor id
		const previousBooking =
			bookings &&
			bookings.find((booking) => booking.vendor_profile_id === venue.id);
		if (previousBooking) {
			booking.id = previousBooking.id;
		}

		const outputVenue = transformVenue({
			venue,
			member: this._member,
			address,
		});

		return (
			(outputVenue &&
				Object.keys(outputVenue).length && {
					booking: {
						...outputVenue,
						...booking,
					},
				}) ||
			{}
		);
	};

	getBookings() {
		if (!this._member) {
			return Promise.resolve();
		}

		return this._client
			.getBookings(this._member.id, { category_code: CATEGORY_CODES.REC })
			.then((response) => response && response.bookings)
			.catch((error) => {});
	}

	_upsertBooking() {
		const receptionVenue =
			this._data &&
			this._data.venue_picker &&
			this._data.venue_picker.receptionVenue;
		const venue =
			receptionVenue &&
			(receptionVenue.storefront || receptionVenue.googlePlace);
		const address = receptionVenue && receptionVenue.address_components;
		const isVenue = [CATEGORY_CODES.REC].includes(this._category);
		const hasVenue = !!venue;
		const hasAddress = !!address;

		// not logged or no data passed, then exit
		if (!(this._member && this._data && !isVenue && hasVenue && hasAddress)) {
			return Promise.resolve();
		}

		return this.getBookings()
			.then((bookings) =>
				this._buildBooking({
					bookings,
					venue,
					address,
				}),
			)
			.then((booking) => this._client.upsertBooking(booking));
	}

	upsert() {
		return Promise.all([this._upsertWedding(), this._upsertBooking()]);
	}

	_transformAndClean = (source, mappings) => {
		const transformed = Hoek.transform(source, mappings);
		// remove undefined properties
		const cleaned = Hoek.merge({}, transformed, false, true);
		return cleaned;
	};
}

export default WeddingApi;
